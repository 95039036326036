<template>
  <v-app :style="colorVapp">
    <div id="app">
      <onebox_admin_toolbar
        :quicksearch="true"
        :parentfolder="parentfolder"
        @inputdata="filteredItems"
        @loadfile="fn_loadfileandfolder()"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
      ></onebox_admin_toolbar>
      <v-content>
        <v-card class="elevation-0">
          <v-divider></v-divider>
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <!-- <v-toolbar dense class="elevation-0">
            <v-avatar color="primary" size="35">
              <v-icon dark size="20">folder</v-icon>
            </v-avatar>
            <v-toolbar-title>
              <v-breadcrumbs :items="navigate" divider=">">
                <template v-slot:item="props">
                  <v-breadcrumbs-item
                    style="cursor: pointer;"
                    @click="gotofolder(props.item.text[1])"
                  >
                    <span
                      style="color: #1565C0; font-size: 18px; font-weight: 600"
                    >{{ props.item.text[0] }}</span>
                  </v-breadcrumbs-item>
                </template>
              </v-breadcrumbs>
             
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn fab small text @click="backtopreventfolder()" color="primary">
                <v-icon>keyboard_backspace</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>-->

          <!-- <span style="color: #1565C0; font-weight: 600;">&nbsp;{{ foldername }}</span> -->

          <v-list nav :color="color.BG">
            <v-list-item class="text-left">
              <v-btn
                @mouseover="show_back = true"
                @mouseleave="show_back = false"
                fab
                small
                :color="color.theme"
                @click="backtopreventfolder()"
                class="ma-2"
                v-if="this.navigate.length > 1"
              >
                <v-icon color="white">keyboard_backspace</v-icon>
                <v-badge
                  v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-35px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge
                  v-else
                  style="z-index:10000;bottom:-45px;left:-27px;"
                  :value="show_back"
                  :content="$t('balloon.back')"
                  transition="slide-y-transition"
                ></v-badge>
              </v-btn>
              <v-list-item-avatar class="text-center mr-2">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                    <template v-slot:item="props">
                      <v-breadcrumbs-item
                        style="cursor: pointer;"
                        :disabled="loaddataprogress"
                        @click.stop="gotofolder(props.item.text[1])"
                      >
                        <span :style="headerPage">{{
                          props.item.text[0]
                        }}</span>
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-btn
                  fab
                  small
                  text
                  @click="backtopreventfolder()"
                  :color="color.theme"
                >
                  <v-icon>keyboard_backspace</v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
          </v-list>

          <!-- <v-divider></v-divider> -->

          <v-card
            class="elevation-0"
            :color="color.BG"
            id="thiscontainer_directoryfile"
          >
            <v-card-text class="pa-0">
              <v-container fluid @contextmenu="show">
                <v-layout row wrap justify-center fill-height>
                  <v-flex lg12 class="ma-4" v-if="loaddataprogress === true">
                    <!-- <div class="text-center">
                      <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                    </div> -->
                    <v-skeleton-loader
                      class="mx-auto"
                      type="table"
                    ></v-skeleton-loader>
                  </v-flex>
                  <v-flex lg12 class="ma-4" v-else>
                    <v-layout row wrap class="pl-3 pr-3">
                      <!-- <v-flex lg2 xs4>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          :item-color="color.theme"
                          :color="color.theme"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex> -->
                      <v-flex lg2>
                        <v-text-field
                          class="pl-1"
                          :label="$t('admin.search')"
                          v-model="search_item"
                          clearable
                          outlined
                          dense
                          :color="color.theme"
                          append-icon="search"
                          @click:clear="fn_loadfileandfolder()"
                        ></v-text-field>
                      </v-flex>
                      <v-flex lg1 class="pl-1">
                        <v-btn
                          :color="color.theme"
                          :dark="color.darkTheme"
                          @click="fn_search_fileandfolder()"
                          >{{ $t("admin.search") }}</v-btn
                        >
                      </v-flex>
                      <v-flex lg2 offset-lg7>
                        <!-- <v-menu transition="slide-y-transition" close-on-click offset-y > -->
                        <!-- <template v-slot:activator="{ on }"> -->
                        <div class="text-center mr-3">
                          <v-btn
                            @click.stop="openfolder()"
                            light
                            depressed
                            block
                            :color="color.theme"
                            :dark="color.darkTheme"
                          >
                            <v-icon class="mr-2">mdi-cloud-upload</v-icon>
                            {{ $t("uploadfolder.name") }}
                          </v-btn>
                        </div>
                        <!-- </template> -->
                        <!-- <v-list nav> -->
                        <!-- <v-list-item @click.stop="openfile()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t('uploadfile.name') }}</v-list-item-title>
            </v-list-item> -->
                        <!-- <v-list-item @click.stop="openfolder()">
              <v-list-item-avatar>
                <v-icon size="30" color="blue-grey darken-4">mdi-folder-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title style="color: #263238">{{ $t('uploadfolder.name') }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
                        <!-- </v-menu> -->
                      </v-flex>
                    </v-layout>
                    <div v-if="resolutionScreen < 500">
                      <v-list
                        two-line
                        class="mb-6 pb-6 elevation-0"
                        v-if="rootfile.length !== 0"
                      >
                        <v-list-item
                          v-for="item in rootfile"
                          :key="item.title"
                          @contextmenu="rightclickadmin($event, item)"
                          @click="
                            gotodirectory(item.file_id, item.file_type, item)
                          "
                          style="cursor: pointer;"
                        >
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              overlap
                              icon="link"
                              v-if="
                                item.file_status_sharelink === 'Y' ||
                                  item.file_status_sharelink === 'P'
                              "
                            >
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-icon v-else large :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                            <v-chip
                              x-small
                              color="yellow darken-2"
                              dark
                              v-if="item.status_updating !== 'Done'"
                              >{{ $t("admin.update") }}</v-chip
                            >
                          </v-list-item-action>

                          <v-list-item-content>
                            <!-- มัยแก้ตรงนี้ -->
                          <v-list-item-title>
                          {{ item.file_name }}
                          <v-icon dark small class="pr-1" color="error" v-if="item.status_lock === 'Y'">mdi-lock</v-icon>
                          </v-list-item-title>
                          <!-- /////////// -->
                            <v-list-item-subtitle
                              v-text="calculatesize(item.file_size)"
                            ></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click.stop="rightclickadmin($event, item)"
                            >
                              <v-icon>settings</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider inset></v-divider>
                      </v-list>
                      <v-list v-else class=" elevation-0">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>

                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        :single-select="false"
                        item-key="file_id"
                        :show-select="statusmutipledownload"
                        :no-data-text="$t('tablefile.empty')"
                        class="elevation-0"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                        </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_lastdtm`]="{ header }">
                          <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr
                            style="cursor: pointer"
                            @contextmenu="rightclickadmin($event, props.item)"
                            @dblclick="
                              gotodirectory(
                                props.item.file_id,
                                props.item.file_type,
                                props.item
                              )
                            "
                          >
                            <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                            </td>-->
                            <td
                              class="text-center"
                              v-show="statusmutipledownload"
                            >
                              <v-checkbox
                                v-if="
                                  props.item.file_type != 'folder' &&
                                    props.item.file_status != 'S'
                                "
                                v-model="filemultipledownload"
                                :value="props.item"
                              />
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                              <v-chip
                                x-small
                                color="yellow darken-2"
                                dark
                                v-if="props.item.status_updating !== 'Done'"
                                >{{ $t("admin.update") }}</v-chip
                              >
                            </td>
                            <td width="33%">
                              <!-- มัยเพิ่ม -->
                              <!-- <v-icon color="error" small>lock</v-icon> -->
                              <v-tooltip top v-if="props.item.status_lock === 'Y'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    small
                                    color="error"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    lock
                                  </v-icon>
                                </template>
                                <span>{{ props.item.file_type === 'folder' ? 'โฟลเดอร์ถูกล็อค': 'ไฟล์ถูกล็อค' }}</span>
                              </v-tooltip>
                              <!-- ///////////// -->
                              {{ props.item.file_name | subStr }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_status_share === 'Y'"
                                >group_add</v-icon
                              >
                            </td>
                            <td width="15%" v-if="$t('default') === 'th'">
                              {{ props.item.file_owner_th }}
                            </td>
                            <td width="15%" v-else>
                              {{ props.item.file_owner_eng }}
                              <v-icon
                                small
                                color="orange"
                                v-if="props.item.file_status_share === 'Y'"
                                >group_add</v-icon
                              >
                            </td>
                            <td width="18%" class="text-center">
                              {{ formatdatetime(props.item.file_lastdtm) }}
                            </td>
                            <td width="15%" class="text-center">
                              {{ calculatesize(props.item.file_size) }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                            ></td>
                            <td width="12%" v-else class="text-center">
                              <!-- <span>
                                <v-btn
                                  class="elevation-0"
                                  fab
                                  small
                                  @click="setPermissionFileStar(props.item,'N')"
                                  v-if="props.item.file_status === 'S'"
                                >
                                  <v-icon color="amber">star</v-icon>
                                </v-btn>
                                <v-btn
                                  class="elevation-0"
                                  fab
                                  small
                                  @click="setPermissionFileStar(props.item,'S')"
                                  v-else
                                >
                                  <v-icon>star</v-icon>
                                </v-btn>
                              </span> -->
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="rightclickadmin($event, props.item)"
                              >
                                <v-icon>settings</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11>
                        <v-pagination
                          :total-visible="10"
                          v-model="page"
                          :length="pageCount"
                          :color="color.theme"
                          v-if="totaldata.totalall > 0"
                          @input="fn_loadfileandfolder()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!-- <scroll-loader class="pa-0" :loader-method="fn_loadfileandfolder" :loader-disable="disablescrolling">
              </scroll-loader> 
               <div class="text-center" v-if="loaddataprogress === true">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>    -->
                <div class="text-center ma-2">
                  <v-snackbar
                    v-model="snackbar"
                    bottom
                    right
                    :timeout="timeout"
                    :color="color.chipText"
                  >
                    <v-list color="white" style="padding-top: 0px;">
                      <v-toolbar
                        style="height:40px; width:350px;"
                        :color="color.theme"
                        dense
                        short
                        dark
                        flat
                        fab
                      >
                        {{ $t("uploadFile.header") }}
                        <v-spacer></v-spacer>
                        <v-btn
                          :color="color.chipText"
                          text
                          @click="snackbar = false"
                          fab
                          x-small
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-list-item
                        v-for="(item, i) in listDataUpload"
                        :key="i"
                        dense
                        style="height:40px; width:350px;"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-badge
                          :value="item.showUpload"
                          color="deep-purple accent-4"
                          :content="item.status"
                          left
                          transition="slide-x-transition"
                        ></v-badge>
                        <v-progress-circular
                          :rotate="90"
                          :size="30"
                          :width="3"
                          :value="item.value"
                          :color="item.color"
                          @mouseover="item.showUpload = true"
                          @mouseleave="item.showUpload = false"
                        >
                          <v-icon v-if="item.value === 100" :color="item.color"
                            >mdi-check-bold</v-icon
                          >
                          <v-icon
                            v-else-if="item.value === 500"
                            :color="item.color"
                            >mdi-exclamation</v-icon
                          >
                          <v-list-item-title
                            v-if="item.value <= 95"
                            v-text="item.value"
                          ></v-list-item-title>
                        </v-progress-circular>
                        <v-btn
                          v-if="item.value != 100 && item.value != 500"
                          :color="color.theme"
                          text
                          @click.stop="
                            cancel(item),
                              (item.value = 500),
                              (item.color = 'red'),
                              (item.status = 'Cancel Upload file by user')
                          "
                          >X</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-snackbar>
                </div>
                <!-- snackbardowload -->
                <div class="text-center ma-2">
                  <v-snackbar
                    v-model="snackbardowload"
                    bottom
                    right
                    color="white"
                  >
                    <v-list color="white" style="padding-top: 0px;">
                      <v-toolbar :color="color.theme" dense short dark flat fab>
                        {{ $t("myinboxPage.dowload.dowloading") }}
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red"
                          text
                          @click="snackbardowload = false"
                          fab
                          x-small
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-list-item dense style="height:40px; width:400px;">
                        <v-list-item-content>
                          <v-list-item-title>{{ datetime }}</v-list-item-title>
                        </v-list-item-content>
                        <v-progress-circular
                          :rotate="90"
                          :size="35"
                          :width="3"
                          :value="percentCompleted"
                          color="teal"
                          style="margin-left:25px"
                          >&nbsp; {{ percentCompleted }}</v-progress-circular
                        >
                      </v-list-item>
                    </v-list>
                  </v-snackbar>
                </div>
              </v-container>
              <!-- <previewfile
                :show="openpreviewfile"
                :filedata="currentfile"
                :parentfolder="parentfolder"
                @closepreviewfile="openpreviewfile = false"
                @openpreviewfile="openpreviewfile = true"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></previewfile> -->
              <!-- <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                @close="loadstorage(),loadfolder(), openUploadfiles = false"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfiles> -->
              <!-- <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                @close="loadfolder(), openUploadfolder = false"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfolder> -->
              <!-- <mainrightclick
                :show="showMenu"
                :parentfolder="parentfolder"
                :AreaX="x"
                :AreaY="y"
                @loaddatafrommainright="loadstorage(),loadfolder(), showMenu = false"
                @closemainonly="showMenu = false"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></mainrightclick> -->
              <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
              <!-- <currentfilerightclick
                :parentfolder="parentfolder"
                :parentfoldername="foldername"
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                @callupdatestatusfile="setPermissionFileStar"
                @closecurrent="loadfolder(), showcurrentMenu= false"
                @closecurrentonly="showcurrentMenu = false"
                :file="currentfile"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></currentfilerightclick> -->
              <!-- <setpermissionfile
            :show="opensetPermissionFile"    
            :filedata="currentfile"       
            @closesetPermissionFile="opensetPermissionFile = false,loadfolder()"
            @opensetPermissionFile="opensetPermissionFile = true"
            @closeDrag="$emit('closeDrag')"
          ></setpermissionfile> -->
              <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                @close="
                  closeDialog(),
                    (openUploadfiles = false),
                    $emit('callstorage'),
                    $emit('openDrag')
                "
                @openuploadfile="openUploadfiles = true"
                @closeDrag="$emit('closeDrag')"
              ></uploadfiles>
              <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                @close="openUploadfolder = false"
                @openuploadfolder="openUploadfolder = true"
                @closeDrag="$emit('closeDrag')"
                @closeandreload="
                  (openUploadfolder = false), fn_loadfileandfolder()
                "
              ></uploadfolder>
              <adminrightclick
                :parentfolder="parentfolder"
                :show="showadminMenu"
                :AreaX="x"
                :AreaY="y"
                @closeinboxmenu="showadminMenu = false"
                :file="currentfile"
                @downloadfile="downloadfile(currentinboxfile)"
                @loaddata="fn_loadfileandfolder(), (showadminMenu = false)"
                @unlocknotsuccess="(showadminMenu = false)"
              ></adminrightclick>
            </v-card-text>
          </v-card>
        </v-card>
      </v-content>
    </div>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
// Import Component
//const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
//const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
//const currentfilerightclick = () => import("../components/contextmenu/currentfilerightclick");
//const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const onebox_admin_toolbar = () =>
  import("../components/layout/layout-admin-toolbar");
//const previewfile = () => import("../components/optional/dialog-preview");
const adminrightclick = () =>
  import("../components/contextmenu/adminrightclick");
const setpermissionfile = () =>
  import("../components/optional/dialog-setpermissionfile");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles2");
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    adminrightclick,
    onebox_admin_toolbar,
    VueFullScreenFileDrop,
    setpermissionfile,
    uploadfiles,
    uploadfolder,
  },
  data: function() {
    return {
      count_check_data: 0,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      disablescrolling: true,
      openUploadfiles: false,
      openUploadfolder: false,
      opensetPermissionFile: false,
      percentCompleted: 0,
      datetime: "",
      showadminMenu: false,
      snackbardowload: false,
      listDataUpload: [],
      snackbar: false,
      hover: false,
      timeout: 0,
      countfolder: "",
      countfile: "",
      statusmutipledownload: false,
      filemultipledownload: [],
      search_item: "",
      navigate: [],
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      foldername: "",
      loaddataprogress: true,
      parentfoldername: "",
      parentfolder: this.$route.params.id,
      loadingfile: false,
      openpreviewfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      showMenu: false,
      currentfilename: "",
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          value: "file_name",
          width: "33%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          value: "file_owner",
          width: "15%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "18%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "12%",
          align: "center",
          sortable: false,
        },
      ],
      sortby: "",
      clickSort: false,
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      parent_search: "",
      countdata: 0,
      show_back: false,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totaldata.totalall;
        let s = this.size;
        console.log(Math.ceil(l / s));
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 50) return string.substring(0, 50) + "...";
        else return string;
      }
    },
  },
  methods: {
    filteredItems(search) {
      // this.search = search;
      this.disablescrolling = true;
      if (search !== "") {
        this.rootfile = this.buff;
        let filldata = this.rootfile.filter((item) => {
          return (
            item.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
            item.file_type === "folder"
          );
        });
        this.rootfile = [];
        filldata.forEach((filedata) => {
          this.rootfile.push(filedata);
        });
      } else {
        this.rootfile = this.buff;
      }
    },
    closeDialog() {
      this.openUploadfolder = false;
      this.openUploadfiles = false;
      this.loadfolder();
    },
    openfile() {
      //console.log("rot", this.$router.currentRoute.path);
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfile.donotshare")
      // });
      // } else {
      (this.openUploadfiles = true), this.sendParentfolder();
      // }
    },
    openfolder() {
      // if (this.$router.currentRoute.path === "/sharedwithme") {
      // Toast.fire({
      //   icon: "error",
      //   title: this.$t("uploadfolder.donotshare")
      // });
      //} else {
      (this.openUploadfolder = true), this.sendParentfolder();
      // }
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.dataAccountActive["business_info"][
          "my_folder_id"
        ];
      } else {
        this.parentfolder = this.$route.params.id;
      }

      // this.parentfolder = this.dataAccountActive["business_info"][
      //         "my_folder_id"
      //       ];
      console.log("parentfolder", this.parentfolder);
    },
    opensetpermission(file) {
      this.currentfile = file;
      this.opensetPermissionFile = true;
      console.log(this.opensetPermissionFile);
    },
    rightclickadmin(e, filename) {
      // console.log(e);
      e.preventDefault();
      //this.sendParentfolder();
      this.showadminMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.currentfile = filename;
        // this.opensetPermissionFile = true;
        console.log(this.showadminMenu);

        //   this.showadminMenu = true;
        setTimeout(() => {
          this.showadminMenu = true;
        }, 300);
      });
    },
    dragenter(e) {
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
    },
    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },
    drop(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
      // this.uploadFile(files);
      let files = e.dataTransfer.files;
      console.log("Drop files:", files);
      //this.uploadfiles(files);
      this.onDrop(files);
    },
    removeEvent() {
      var self = this;
      window.removeEventListener("dragenter", self.dragenter, true);
      window.removeEventListener("dragleave", self.dragleave, true);
      window.removeEventListener("dragover", self.dragover, true);
      window.removeEventListener("drop", self.drop, true);
    },
    addEvent() {
      if (this.resolutionScreen >= 500) {
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenter, true);
        window.addEventListener("dragleave", self.dragleave, true);
        window.addEventListener("dragover", self.dragover, true);
        window.addEventListener("drop", self.drop, true);
      }
    },
    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.rootfile = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].file_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }

      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
        }
      } else if (this.sortby === "date") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_lastdtm.localeCompare(b.file_lastdtm);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_lastdtm.localeCompare(b.file_lastdtm);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_lastdtm.localeCompare(a.file_lastdtm);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_lastdtm.localeCompare(a.file_lastdtm);
          });
        }
      } else if (this.sortby === "size") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
        }
      } else if (this.sortby === "owner") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
        }
      }
      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.rootfile.push(this.sortdata[i][j]);
        }
      }
      return this.rootfile;
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    backtoroot() {
      this.$router.replace("/mydrive");
    },
    gotodirectory(folderid, type, file) {
      if (type === "folder") {
        this.rootfile = [];
        this.buff = [];
        this.$router.replace({
          path: "/onebox-system-admin-managedirectoryfile/" + folderid,
          // path: "/system-admin-managedirectoryfile/" + folderid,
        });
        this.page = 1;
        this.fn_loadfileandfolder();
        this.parentfolder = this.$route.params.id;
      } else {
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },
    show(e) {
      // console.log(e);
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },
    cancel(item) {
      this.itemC = item;
      console.log(this.itemC);
      this.checkCC = true;
    },
    onDrop(files) {
      this.listDataUpload = [];
      for (let i = 0; i < files.length; i++) {
        let dataUpload = {};
        dataUpload["name"] = files[i].name;
        dataUpload["status"] = "";
        dataUpload["value"] = 0;
        dataUpload["showUpload"] = false;
        this.listDataUpload.push(dataUpload);
        console.log("files", files[i]);
        //this.listDataUpload = this.listDataUpload.filter(item => item !== this.itemC)
      }
      this.uploadfiles(files);
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = "";
        this.parentfoldername = "ROOT";
      } else {
        this.parentfolder = this.$route.params.id;
        this.parentfoldername = this.foldername;
      }
      // this.openUploadfolder = true
    },
    async loadfolder() {
      console.log("testadmindirect");
      //this.page= 1;
      var payload;
      this.loaddataprogress = true;
      this.rootfile = [];
      this.buff = [];
      if (this.dataAccountActive["type"] === "Business") {
        if (
          this.$route.params.id ===
          this.dataAccountActive["business_info"]["my_folder_id"]
        ) {
          var a;
          for (
            a = 0;
            a < this.dataAccountActive["directory_business"].length;
            a++
          ) {
            let datafolder = {};
            datafolder["file_id"] = this.dataAccountActive[
              "directory_business"
            ][a]["department_folder_id"];
            datafolder["file_name"] = this.dataAccountActive[
              "directory_business"
            ][a]["department_name"];
            datafolder["file_size"] = "-";
            datafolder["file_department"] = this.dataAccountActive[
              "directory_business"
            ][a]["department_id"];
            datafolder["file_lastdtm"] = "-";
            datafolder["file_owner_eng"] = "-";
            datafolder["file_owner_th"] = "-";
            datafolder["file_status"] = "M";
            datafolder["file_type"] = "folder";
            datafolder["file_icon"] = ["mdi-folder-heart", "green"];
            this.rootfile.push(datafolder);
            this.buff.push(datafolder);
          }
        }
        if (this.clickSort === true) {
          this.rootfile.push(this.sortFile(this.rootfile));
        }
        let checkPersonalBusiness = this.navigate.findIndex(
          (r) =>
            r.text[1] ===
            this.dataAccountActive["business_info"]["my_folder_id"]
        );
        if (
          this.$route.params.id ===
          this.dataAccountActive["business_info"]["my_folder_id"]
        ) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiอันเก่าไม่ใช้
            folder_id: this.$route.params.id,
            status: "",
          };
        } else if (checkPersonalBusiness !== -1) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiอันเก่าไม่ใช้
            folder_id: this.$route.params.id,
            status: "",
          };
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status: "",
          };
        }
      } else {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status: "",
        };
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/search_folder_to_setting",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            console.log(response);
            // console.log("data",response.data.folder.length);

            let checknavigate = this.navigate.findIndex(
              (y) => y.text[1] === this.$route.params.id
            );
            if (checknavigate === -1) {
              if (this.dataAccountActive["type"] === "Business") {
                if (
                  response.data.your_folder[0]["folder_id"] ===
                  this.dataAccountActive["business_info"]["my_folder_id"]
                ) {
                  this.navigate.push({
                    text: [
                      this.$store.getters.dataTypeusercurrent(
                        this.$store.state.account_active["type"],
                        this.$t("default")
                      ),
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                } else {
                  this.navigate.push({
                    text: [
                      response.data.your_folder[0]["folder_name"],
                      response.data.your_folder[0]["folder_id"],
                    ],
                    disabled: false,
                  });
                }
              } else {
                this.navigate.push({
                  text: [
                    response.data.your_folder[0]["folder_name"],
                    response.data.your_folder[0]["folder_id"],
                  ],
                  disabled: false,
                });
              }
            } else {
              let datanavigate = this.navigate;
              let i;
              this.navigate = [];
              for (i = 0; i <= checknavigate; i++) {
                this.navigate.push(datanavigate[i]);
              }
            }
            this.foldername = response.data.your_folder[0]["folder_name"];
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_size"] = "-";
              datafolder["file_lastdtm"] =
                response.data.folder[i]["cre_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] =
                response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] =
                response.data.folder[i]["status_share_link"];
              datafolder["file_status"] =
                response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] =
                response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              datafolder["file_department"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon("folder");
              datafolder["permission_setting"] =
                response.data.folder[i]["permission_setting"];
              datafolder["permission_department_setting"] =
                response.data.folder[i]["permission_department_setting"];
              datafolder["permission_business_setting"] =
                response.data.folder[i]["permission_business_setting"];
              datafolder["status_updating"] =
                response.data.folder[i]["status_updating"];
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.loadfiles(this.$route.params.id, "");
            this.countfolder = response.data.folder.length;
            console.log("rootfile", this.rootfile);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
          });
        });
    },
    async loadfiles(folder, status) {
      let payload = {};
      if (this.dataAccountActive["type"] === "Business") {
        let checkPersonalBusiness = this.navigate.findIndex(
          (r) =>
            r.text[1] ===
            this.dataAccountActive["business_info"]["my_folder_id"]
        );
        if (
          folder === this.dataAccountActive["business_info"]["my_folder_id"]
        ) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiเก่าไม่ใช้
            folder_id: folder,
            status: status,
          };
        } else if (checkPersonalBusiness !== -1) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiเก่าไม่ใช้
            folder_id: folder,
            status: status,
          };
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: folder,
            status: status,
          };
        }
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: status,
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            console.log(response);
            // console.log("file",response.data.data.length);

            let i;
            let rawdata = response.data.data;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = rawdata[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = rawdata[i]["id"];
              datafolder["file_name"] = rawdata[i]["filename"];
              datafolder["file_size"] = rawdata[i]["size_file"];
              datafolder["file_lastdtm"] =
                response.data.data[i]["last_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_status"] = rawdata[i]["status_file"];
              datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
              datafolder["file_owner_th"] = rawdata[i]["name_th"];
              datafolder["file_linkshare"] = rawdata[i]["link"];
              datafolder["file_status_share"] = rawdata[i]["status_share"];
              datafolder["file_status_sharelink"] =
                rawdata[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["file_permission"] = "";
              datafolder["permission_setting"] =
                rawdata[i]["permission_setting"];
              datafolder["permission_department_setting"] =
                rawdata[i]["permission_department_setting"];
              datafolder["permission_business_setting"] =
                rawdata[i]["permission_business_setting"];
              datafolder["status_updating"] = rawdata[i]["status_updating"];
              datafolder["file_icon"] = gb_setIconFiles.fn_seticon(
                typefile[typefile.length - 1]
              );
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
            }
            console.log("root", this.rootfile);

            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.countfile = response.data.data.length;
            // console.log(this.rootfile);
            this.loaddataprogress = false;
            this.loadingdata = false;
          } else {
            this.loadingdata = false;
            this.loaddataprogress = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loadingdata = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_check_type(parameter) {
      let type = "";
      if (parameter === "normal_folder") {
        type = "folder";
      } else if (parameter === "dept_folder") {
        type = "folder";
      } else if (parameter === "business_folder") {
        type = "folder";
      } else if (parameter === "compliance_folder") {
        type = "folder";
      } else {
        type = "file";
      }
      return type;
    },
    seticon(parameter, item) {
      // console.log(parameter);
      // let parameter_ = parameter.toLowerCase()
      let dataicon;
      if (item === "file") {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (parameter === "csv") {
          dataicon = ["mdi-file-table", "green"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      } else if (item === "normal_folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else if (item === "dept_folder") {
        dataicon = ["mdi-folder-heart", "green"];
      } else if (item === "business_folder") {
        dataicon = ["mdi-folder-network", "blue darken-4"];
      } else if (item === "compliance_folder") {
        dataicon = ["mdi-book-open-page-variant", "pink lighten-2"];
      }
      return dataicon;
    },
    // loadstorage() {
    //   this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
    // },
    backtopreventfolder() {
      this.navigate.pop();
      if (this.navigate.length === 1) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        this.page = 1;
        this.$router.replace("/onebox-system-admin-managefile");
        // this.$router.replace("/system-admin-managefile");
      } else {
        this.loadingdata = true;
        this.$router.replace({
          path:
            "/onebox-system-admin-managedirectoryfile/" +
            // "/system-admin-managedirectoryfile/" +
            this.navigate[this.navigate.length - 1]["text"][1],
        });
        this.page = 1;
        this.fn_loadfileandfolder();
        console.log("backtopreventfolder", this.page);

        this.parentfolder = this.$route.params.id;
      }
    },
    gotofolder(_folderid) {
      console.log("_folderid", this.navigate);
      if (_folderid === this.dataAccountActive["directory_id"]) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        this.page = 1;
        this.$router.replace("/onebox-system-admin-managefile");
        // this.$router.replace("/system-admin-managefile");
      } else {
        this.loadingdata = true;
        this.$router.replace({
          path: "/onebox-system-admin-managedirectoryfile/" + _folderid,
          // path: "/system-admin-managedirectoryfile/" + _folderid,
        });
        this.page = 1;
        this.fn_loadfileandfolder();
        console.log(" this.page", this.page);
        this.parentfolder = this.$route.params.id;
      }
    },
    changepage() {
      this.loaddataprogress = true;
      setTimeout(() => {
        this.loaddataprogress = false;
      }, 1000);
    },
    async fn_loadfileandfolder() {
      //this.page=1;
      this.search_item = "";
      console.log("this.$route.params.id", this.$route.params.id, this.page);
      this.disablescrolling = true;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      let payload;
      // console.log(cal_offset)
      //if (cal_offset == 0) {
      this.rootfile = [];
      this.buff = [];
      //}

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let checkPersonalBusiness = this.navigate.findIndex(
            (r) =>
              r.text[1] ===
              this.dataAccountActive["business_info"]["my_folder_id"]
          );
          if (
            this.$route.params.id ===
            this.dataAccountActive["business_info"]["my_folder_id"]
          ) {
            console.log("rootfileDepartment", this.rootfile);
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          } else if (checkPersonalBusiness >= 0) {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          } else {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          }
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status_file: "",
            status_folder: "",
            limit: cal_limit,
            offset: cal_offset,
          };
        }
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status_file: "",
          status_folder: "",
          limit: cal_limit,
          offset: cal_offset,
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_to_setting",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          this.loaddataprogress = false;

          // ตรวจสอบ  Navigation Bar
          let checknavigate = this.navigate.findIndex(
            (y) => y.text[1] === this.$route.params.id
          );
          if (checknavigate === -1) {
            if (
              this.dataAccountActive["type"] === "Business" &&
              response.data.your_folder[0]["id"] ===
                this.dataAccountActive["business_info"]["my_folder_id"]
            ) {
              this.navigate.push({
                text: [
                  this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  ),
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["id"]
                    : this.$route.params.id,
                ],
                disabled: false,
              });
            } else {
              this.navigate.push({
                text: [
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["name"]
                    : "-",
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["id"]
                    : this.$route.params.id,
                ],
                disabled: false,
              });
            }
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          if (response.data.status === "OK") {
            this.count_check_data = response.data.data.length;
            for (let i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["data_name_th"].split(".");
              datafolder["account_id"] = response.data.data[i]["account_id"];
              datafolder["user_id"] = response.data.data[i]["user_id"];
              datafolder["file_size"] = response.data.data[i]["size"];
              datafolder["permission_setting"] =
                response.data.data[i]["permission_setting"];
              datafolder["permission_department_setting"] =
                response.data.data[i]["permission_department_setting"];
              datafolder["permission_business_setting"] =
                response.data.data[i]["permission_business_setting"];
              datafolder["permission_role_setting"] =
                response.data.data[i]["permission_role_setting"];
              datafolder["status_updating"] =
                response.data.data[i]["status_updating"];
              datafolder["data_type"] = response.data.data[i]["data_type"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["data_name_th"];
              datafolder["file_name_eng"] =
                response.data.data[i]["data_name_eng"];
              datafolder["file_department"] = "";
              datafolder["business_id"] = response.data.data[i]["business_id"];
              datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["priority"] = response.data.data[i]["priority"];
              datafolder["file_type"] = this.fn_check_type(
                response.data.data[i]["data_type"]
              );
              datafolder["file_icon"] = 
                this.fn_check_type(response.data.data[i]["data_type"]) === "file" ?
                  gb_setIconFiles.fn_seticon(typefile[typefile.length - 1]) : 
                  gb_setIconFiles.fn_seticon("folder")
              datafolder["status_lock"] = response.data.data[i]["status_lock"]; // เพิ่มตรงนี้
              console.log(" datafolder[file_type]",  datafolder["file_type"]);
              console.log("datafolder[file_icon]", datafolder["file_icon"]);
              console.log("typefile[typefile.length - 1]", typefile[typefile.length - 1]);
              this.rootfile.push(datafolder);
            }
            this.parent_search = response.data.your_folder[0]["id"];
            //  this.countdata = response.data.total;
            this.totaldata = {
              totalall: response.data.total,
              totalfile: response.data.total_file,
              totalfolder: response.data.total_folder,
            };
            //console.log("--------------------",this.rootfile.length,this.totaldata['totalall']);
            this.count_file = response.data.total_file;
            this.count_folder = response.data.total_folder;
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;
            console.log(
              "Newrootfile",
              this.totaldata["totalall"],
              this.rootfile.length,
              response.data.data.length,
              this.count_check_data
            );
            // เรียกใช้ creator_name
            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].file_owner_th === "" ||
                this.rootfile[y].file_owner_eng === "" ||
                this.rootfile[y].file_owner_th === undefined ||
                this.rootfile[y].file_owner_eng === undefined
              ) {
                this.get_name_owner(this.rootfile[y]);
              }
            }
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          // ตรวจสอบ  Navigation Bar
          let checknavigate = this.navigate.findIndex(
            (y) => y.text[1] === this.$route.params.id
          );
          if (checknavigate === -1) {
            if (
              this.dataAccountActive["type"] === "Business" &&
              this.$route.params.id ===
                this.dataAccountActive["business_info"]["my_folder_id"]
            ) {
              this.navigate.push({
                text: [
                  this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  ),
                  this.$route.params.id,
                ],
                disabled: false,
              });
            } else {
              this.navigate.push({
                text: ["-", this.$route.params.id],
                disabled: false,
              });
            }
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile) {
      console.log("listrootfile", listrootfile);
      let payload = {
        data_id: listrootfile.file_id,
        data_type: listrootfile.file_type === "folder" ? "folder" : "file",
        type_search: "creator",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      console.log("auth.code", auth.code);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/v1/get/data/creator_name",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng;
            listrootfile.file_owner_th = response.data.name.name_th;
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    async fn_loadfileandfolder_child() {
      console.log("this.$route.params.id", this.$route.params.id);
      this.disablescrolling = true;
      this.loaddataprogress = true;
      this.page = 1;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page++ - 1);
      let payload;
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let checkPersonalBusiness = this.navigate.findIndex(
            (r) =>
              r.text[1] ===
              this.dataAccountActive["business_info"]["my_folder_id"]
          );
          if (
            this.$route.params.id ===
            this.dataAccountActive["business_info"]["my_folder_id"]
          ) {
            console.log("rootfileDepartment", this.rootfile);
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          } else if (checkPersonalBusiness >= 0) {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          } else {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
            };
          }
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status_file: "",
            status_folder: "",
            limit: cal_limit,
            offset: cal_offset,
          };
        }
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status_file: "",
          status_folder: "",
          limit: cal_limit,
          offset: cal_offset,
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_to_setting",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          this.loaddataprogress = false;

          // ตรวจสอบ  Navigation Bar
          let checknavigate = this.navigate.findIndex(
            (y) => y.text[1] === this.$route.params.id
          );
          if (checknavigate === -1) {
            if (
              this.dataAccountActive["type"] === "Business" &&
              response.data.your_folder[0]["id"] ===
                this.dataAccountActive["business_info"]["my_folder_id"]
            ) {
              this.navigate.push({
                text: [
                  this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  ),
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["id"]
                    : this.$route.params.id,
                ],
                disabled: false,
              });
            } else {
              this.navigate.push({
                text: [
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["name"]
                    : "-",
                  response.data.status === "OK"
                    ? response.data.your_folder[0]["id"]
                    : this.$route.params.id,
                ],
                disabled: false,
              });
            }
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.data.length; i++) {
              this.count_check_data++;
              let datafolder = {};
              let typefile = response.data.data[i]["data_name_th"].split(".");
              datafolder["account_id"] = response.data.data[i]["account_id"];
              datafolder["user_id"] = response.data.data[i]["user_id"];
              datafolder["file_size"] = response.data.data[i]["size"];
              datafolder["permission_setting"] =
                response.data.data[i]["permission_setting"];
              datafolder["permission_department_setting"] =
                response.data.data[i]["permission_department_setting"];
              datafolder["permission_business_setting"] =
                response.data.data[i]["permission_business_setting"];
              datafolder["permission_role_setting"] =
                response.data.data[i]["permission_role_setting"];
              datafolder["status_updating"] =
                response.data.data[i]["status_updating"];
              datafolder["data_type"] = response.data.data[i]["data_type"];
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["data_name_th"];
              datafolder["file_name_eng"] =
                response.data.data[i]["data_name_eng"];
              datafolder["file_department"] = "";
              datafolder["business_id"] = response.data.data[i]["business_id"];
              datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["priority"] = response.data.data[i]["priority"];
              datafolder["file_type"] = this.fn_check_type(
                response.data.data[i]["data_type"]
              );
              datafolder["file_icon"] = 
                this.fn_check_type(response.data.data[i]["data_type"]) === "file" ?
                  gb_setIconFiles.fn_seticon(typefile[typefile.length - 1]) : 
                  gb_setIconFiles.fn_seticon("folder")
              datafolder["status_lock"] = response.data.data[i]["status_lock"]; // เพิ่มตรงนี้
              this.rootfile.push(datafolder);
            }
            this.parent_search = response.data.your_folder[0]["id"];
            //  this.countdata = response.data.total;
            this.totaldata = {
              totalall: response.data.total,
              totalfile: response.data.total_file,
              totalfolder: response.data.total_folder,
            };
            //console.log("--------------------",this.rootfile.length,this.totaldata['totalall']);
            this.count_file = response.data.total_file;
            this.count_folder = response.data.total_folder;
            //this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            //this.aspectData=[{ aspect: response.data.your_folder[0]["aspect"],aspect_key: response.data.your_folder[0]["aspect_key"],}];
            this.disablescrolling =
              this.totaldata["totalall"] <= this.rootfile.length;
            //console.log("Newrootfile",this.totaldata['totalall'],this.rootfile.length,response.data.data.length,this.count_check_data);
            // เรียกใช้ creator_name
            for (let y = 0; y < this.rootfile.length; y++) {
              if (
                this.rootfile[y].file_owner_th === "" ||
                this.rootfile[y].file_owner_eng === ""
              ) {
                this.get_name_owner(this.rootfile[y]);
              }
              console.log("master yi", y);
            }
          } else {
            this.disablescrolling = true;
          }
        })
        .catch((error) => {
          console.log(error);
          // ตรวจสอบ  Navigation Bar
          let checknavigate = this.navigate.findIndex(
            (y) => y.text[1] === this.$route.params.id
          );
          if (checknavigate === -1) {
            if (
              this.dataAccountActive["type"] === "Business" &&
              this.$route.params.id ===
                this.dataAccountActive["business_info"]["my_folder_id"]
            ) {
              this.navigate.push({
                text: [
                  this.$store.getters.dataTypeusercurrent(
                    this.$store.state.account_active["type"],
                    this.$t("default")
                  ),
                  this.$route.params.id,
                ],
                disabled: false,
              });
            } else {
              this.navigate.push({
                text: ["-", this.$route.params.id],
                disabled: false,
              });
            }
          } else {
            let datanavigate = this.navigate;
            let i;
            this.navigate = [];
            for (i = 0; i <= checknavigate; i++) {
              this.navigate.push(datanavigate[i]);
            }
          }
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async fn_search_fileandfolder() {
      if (this.search_item !== "" && this.search_item !== null) {
        this.disablescrolling = true;
        this.rootfile = [];
        this.buff = [];

        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.parent_search,
          name: this.search_item,
        };
        console.log("folderid", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/search_file_folder_in_setting",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log("folder", response.data.status);
            // console.log("count",response.data.folder.length);
            this.loaddataprogresstable = false;
            if (response.data.status === "OK") {
              console.log("folder", response);
              for (let i = 0; i < response.data.data.length; i++) {
                let datafolder = {};
                let typefile = response.data.data[i]["data_name_th"].split(".");
                datafolder["account_id"] = response.data.data[i]["account_id"];
                datafolder["user_id"] = response.data.data[i]["user_id"];
                datafolder["file_size"] = response.data.data[i]["size"];
                datafolder["permission_setting"] =
                  response.data.data[i]["permission_setting"];
                datafolder["permission_department_setting"] =
                  response.data.data[i]["permission_department_setting"];
                datafolder["permission_business_setting"] =
                  response.data.data[i]["permission_business_setting"];
                datafolder["status_updating"] =
                  response.data.data[i]["status_updating"];
                datafolder["data_type"] = response.data.data[i]["data_type"];
                datafolder["file_id"] = response.data.data[i]["id"];
                datafolder["file_name"] = response.data.data[i]["data_name_th"];
                datafolder["file_name_eng"] =
                  response.data.data[i]["data_name_eng"];
                datafolder["file_department"] = "";
                datafolder["business_id"] =
                  response.data.data[i]["business_id"];
                datafolder["file_lastdtm"] = response.data.data[i]["cre_dtm"];
                datafolder["file_owner_eng"] =
                  response.data.data[i]["name_eng"];
                datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                datafolder["priority"] = response.data.data[i]["priority"];
                datafolder["file_type"] = this.fn_check_type(
                  response.data.data[i]["data_type"]
                );
                datafolder["file_icon"] = 
                  this.fn_check_type(response.data.data[i]["data_type"]) === "file" ?
                    gb_setIconFiles.fn_seticon(typefile[typefile.length - 1]) : 
                    gb_setIconFiles.fn_seticon("folder")
                datafolder["status_lock"] = response.data.data[i]["status_lock"]; // เพิ่มตรงนี้
                this.rootfile.push(datafolder);
              }

              this.countdata = response.data.total;
              this.disablescrolling = this.countdata <= this.rootfile.length;
            } else {
              this.disablescrolling = true;
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.loaddataprogress = false;
            this.loaddataprogresstable = false;
            this.disablescrolling = true;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้",
            });
          });
      } else {
      }
    },
  },
  mounted() {
    var self = this;
    self.removeEvent();
    self.addEvent();

    //self.loadstorage();
    console.log("fid", this.$route.params.id);

    if (
      this.dataDepartmentAccessId === "" &&
      this.dataAccountActive["type"] === "Business"
    ) {
      let a = this.dataAccountActive["directory_business"].findIndex(
        (a) => a.department_folder_id === this.$route.params.id
      );
      if (a !== -1) {
        console.log(
          "testmountedadmin",
          this.dataAccountActive["directory_business"][a]["department_id"]
        );

        this.$store.dispatch(
          "department_access_id",
          this.dataAccountActive["directory_business"][a]["department_id"]
        );
      }
      this.fn_loadfileandfolder();
    } else {
      this.fn_loadfileandfolder();
    }

    this.navigate.push({
      text: [
        this.$t("admin.managefile"),
        this.dataAccountActive["directory_id"],
      ],
      disabled: false,
    });
    //this.disablescrolling=false;
    //this.loaddataprogress = false;
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
#thiscontaineradmindirect {
  height: calc(101vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
